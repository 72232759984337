/* eslint-disable */
<template>
  <div>
    <div class="main-container">
      <h1 class="dark-text fs-18 fw-500" style="margin: 25px 0 10px">{{ name }}</h1>
      <div class="row">
        <div class="col m4 s12 pl-0 input-box">
          <select
            class="browser-default pointer"
            id="select-input"
            v-model="selectedYear"
            @change="reset"
          >
            <option value="0" selected disabled>Select Year</option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
          </select>
          <p class="red-text fs-14" v-if="showYearError">Please select Year</p>
        </div>
        <div class="col m4 s12 pl-0 input-box">
          <select
            class="browser-default pointer"
            id="select-input"
            v-model="selectedWeek"
            :disabled="isMonthSelected || isDateSelected"
            @change="reset"
          >
            <option value="0" selected>Select week</option>
            <option v-for="week in 52" :value="week" :key="week">{{ "Week " + week }}</option>
          </select>
          <!-- <p class="red-text fs-14" v-if="showWeekError">Please select Week or month</p> -->
        </div>
        <div class="col m4 s12 pl-0 input-box">
          <select
            class="browser-default pointer"
            id="select-input"
            v-model="selectedMonth"
            :disabled="isWeekSelected || isDateSelected"
            @change="reset"
          >
            <option value="0" selected>Select month</option>
            <option v-for="(month, index) in 12" :value="index + 1" :key="index">
              {{ new Date(null, index, 1).toLocaleDateString("en", { month: "long" }) }}
            </option>
          </select>
          <!-- <p class="red-text fs-14" v-if="showWeekError">Please select Week or month</p> -->
        </div>
      </div>
      <div class="row">
        <div v-if="ischeckMethod">
          <div class="date-picker-wrapper" :class="{ disabled: isMonthSelected || isWeekSelected }">
            <div class="col m4 s12 m-0 pl-0 input-box">
              <v-date-picker v-model="startDate" mode="date" :max-date="maxDate" :minDate="minDate">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    class="browser-default pointer"
                    id="select-input"
                    :value="inputValue"
                    v-on="inputEvents"
                    readonly
                    placeholder="Start Date"
                  />
                </template>
              </v-date-picker>
              <!-- <p class="red-text fs-14" v-if="showStartDateError">Please select start date</p> -->
            </div>
            <div class="col m4 s12 m-0 input-box">
              <v-date-picker v-model="endDate" mode="date" :max-date="maxDate" :minDate="minDate">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    class="browser-default pointer"
                    id="select-input"
                    :value="inputValue"
                    v-on="inputEvents"
                    readonly
                    placeholder="End Date"
                  />
                </template>
              </v-date-picker>
            </div>
            <!-- <p class="red-text fs-14" v-if="showEndDateError">Please select end date</p> -->
          </div>
        </div>
        <div class="col m2 s12 p-0 input-box">
          <button
            class="test-btn w-100"
            style="
              background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
              padding: 13px 10px;
            "
            @click="getData()"
          >
            {{ filterTriggered ? "Clear" : "Apply" }}
          </button>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div
          class="col m3 s12 pl-0 input-box"
          style="margin-right: 11px"
          v-if="details.length != 0 && showQuestion"
        >
          <select
            class="browser-default pointer"
            id="select-input"
            style="width: 315px"
            v-model="selectedCourse"
            @change="filterByCourse"
          >
            <option value="0" selected disabled>Select Course</option>
            <option :value="1">{{ "All Courses" }}</option>
            <option v-for="course in courseList" :value="course" :key="course">{{ course }}</option>
          </select>
        </div>
        <div
          class="col m3 s12 pl-0 input-box"
          v-if="details.length != 0 && showQuestion && selectedCourse != 0"
        >
          <select
            class="browser-default pointer"
            id="select-input"
            style="width: 315px"
            v-model="selectedSubject"
            @change="filterBySubject"
          >
            <option value="0" selected disabled>Select Subject</option>
            <option :value="1">{{ "All Subjects" }}</option>
            <option v-for="subject in subjectList" :value="subject" :key="subject">
              {{ subject }}
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="details.length > 0 && !showQuestion"
        class="row flex flex-end"
        style="margin: 0 !important"
      >
        <button
          class="test-btn w-100"
          style="background: #008000cc; padding: 13px 10px; width: 215px"
          @click="downloadCsv()"
        >
          Download csv
        </button>
      </div>
    </div>
    <div class="row s12" v-if="details.length > 0">
      <table class="qtbl striped">
        <thead class="data-table-head">
          <tr>
            <th
              :class="{ 'table-head': true, 'question-id': col === 'QuestionId' }"
              v-for="col in tableColumns"
              :key="col"
            >
              {{ col }}
              <span
                v-if="col === 'Accuracy (%)' && showQuestion"
                class="material-icons"
                style="position: relative; top: 6px; margin-left: 2px; cursor: pointer"
                @click="SortByAccuracy()"
                >swap_vert</span
              >
              <span
                v-if="col === 'Attempted' && showQuestion"
                class="material-icons"
                style="position: relative; top: 6px; margin-left: 2px; cursor: pointer"
                @click="QsAttempt()"
                >swap_vert</span
              >
            </th>
            <th v-if="showQuestion" class="table-head">Questions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in tableRows" :key="index">
            <td
              :class="{ 'question-id': key === 'QuestionId', 'custom-cell': true }"
              v-for="(value, key) in row"
              :key="key"
            >
              {{ key === "Accuracy (%)" || key === "Average time (s)" ? Math.round(value) : value }}
            </td>
            <td class="view-ques" v-if="showQuestion" @click="showQuestionModal(row.QuestionId)">
              View Question
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="text-center"
      style="margin-bottom: 20px"
      v-if="details.length === 0 && filterTriggered"
    >
      <img
        :src="require(`../assets/no-result-found.png`)"
        alt=""
        style="width: 200px; opacity: 0.7"
      />
      <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585">
        No details available for selected filter.
      </p>
    </div>
  </div>
  <ViewQuestionModal
    :questionId="questionId"
    :selectedTab="selectedModalTab"
    v-if="showQuesViewModal"
    @closeModal="closeToggle($event)"
  />
</template>
<script>
import moment from "moment";
import exportFromJSON from "export-from-json";
import Mobileapi from "../Mobileapi";
import ViewQuestionModal from "../components/ViewQuestionModal.vue";

export default {
  components: {
    ViewQuestionModal,
  },
  props: {
    apiMethod: String,
    name: String,
    showQuestion: Boolean,
  },
  data() {
    return {
      filteredRows: [],
      selectedCourse: 0,
      selectedSubject: 0,
      courseList: [],
      subjectList: [],
      showQuesViewModal: false,
      isSortClicked: false,
      questionId: 0,
      QuestionDiagramURL: "",
      selectedModalTab: 1,
      questionModal: false,
      conceptTabActive: 0,
      selectedYear: 0,
      affiliationId: "",
      tableColumns: [],
      tableRows: [],
      showYearError: false,
      showWeekError: false,
      selectedWeek: 0,
      details: "",
      selectedMonth: 0,
      selectedDate: 0,
      filterTriggered: false,
      startDate: moment().subtract(7, "days").toDate(),
      endDate: new Date(),
      maxDate: moment().toDate(),
      minDate: moment().subtract(1, "year").toDate(),
      checkDate: false,
    };
  },
  computed: {
    isWeekSelected() {
      return this.selectedWeek !== 0;
    },
    isMonthSelected() {
      return this.selectedMonth !== 0;
    },
    isDateSelected() {
      return this.checkDate !== false;
    },
    ischeckMethod() {
      return (
        this.apiMethod === "getPracticeSelfTestData" ||
        this.apiMethod === "getPracticeSelfTestDataBatchwise" ||
        this.apiMethod === "getPracticeSelfTestDataChapterwise"
      );
    },
  },
  created() {
    const user = this.$store.getters.user;
    this.userDetails = user;
    this.affiliationId = this.userDetails.AffiliationId;
  },
  methods: {
    filterByCourse() {
      this.selectedSubject = 0;
      if (this.selectedCourse === 1) {
        this.tableRows = this.details;
      } else {
        this.tableRows = this.details;
        this.tableRows = this.details.filter((x) => x.Course === this.selectedCourse);
      }
      this.subjectList = [...new Set(this.tableRows.map((x) => x.Subject))];
      this.filteredRows = [...this.tableRows];
    },
    filterBySubject() {
      const rows = [...this.filteredRows];
      if (this.selectedSubject === 1) {
        this.tableRows = rows;
      } else {
        this.tableRows = rows.filter((x) => x.Subject === this.selectedSubject);
      }
    },
    closeToggle(event) {
      this.showQuesViewModal = event;
    },
    SortByAccuracy() {
      if (this.isSortClicked) {
        this.tableRows.sort((a, b) => a["Accuracy (%)"] - b["Accuracy (%)"]);
        this.isSortClicked = false;
      } else {
        this.tableRows.sort((a, b) => b["Accuracy (%)"] - a["Accuracy (%)"]);
        this.isSortClicked = true;
      }
    },
    QsAttempt() {
      if (this.isSortClicked) {
        this.tableRows.sort((a, b) => a.Attempted - b.Attempted);
        this.isSortClicked = false;
      } else {
        this.tableRows.sort((a, b) => b.Attempted - a.Attempted);
        this.isSortClicked = true;
      }
    },
    showQuestionModal(questionId) {
      this.showQuesViewModal = true;
      this.questionId = questionId;
      this.QuestionDiagramURL = "";
      this.selectedModalTab = 1;
      this.questionModal = true;
      this.conceptTabActive = 0;
    },
    reset() {
      this.filterTriggered = false;
      this.details = "";
      if (this.selectedWeek === "0") {
        this.selectedWeek = 0;
      }
      if (this.selectedMonth === "0") {
        this.selectedMonth = 0;
      }
    },
    downloadCsv() {
      const data = this.tableRows.map((item) => {
        const row = {};
        this.tableColumns.forEach((col) => {
          row[col] = item[col];
        });
        return row;
      });
      const fileName = this.name;
      const exportType = exportFromJSON.types.csv;
      const fields = this.tableColumns;
      if (data) {
        exportFromJSON({
          data,
          fileName,
          fields,
          exportType,
        });
      }
    },
    getData() {
      this.selectedCourse = 0;
      this.selectedSubject = 0;
      if (this.selectedMonth !== 0 || this.selectedWeek !== 0) {
        this.startDate = null;
        this.endDate = null;
        this.year = 0;
      }
      if ((this.selectedMonth !== 0 || this.selectedWeek !== 0) && this.selectedYear === 0) {
        this.showYearError = true;
        return;
      }
      this.$store.dispatch("showLoader", true);
      if (!this.filterTriggered) {
        Mobileapi[this.apiMethod](
          {
            affiliationId: this.affiliationId,
            week: this.selectedWeek,
            year: this.selectedYear,
            month: this.selectedMonth,
            startDate: this.startDate,
            endDate: this.endDate,
          },
          (response) => {
            this.showWeekError = false;
            this.showYearError = false;
            this.filterTriggered = true;
            this.$store.dispatch("showLoader", false);
            if (response.responseCode === 200) {
              this.details = response.data;
              const values = response.data;
              this.tableColumns = Object.keys(values[0]);
              this.tableRows = values;
              this.showApply = false;
              this.courseList = [...new Set(this.details.map((x) => x.Course))];
            } else {
              window.M.toast({
                html: "Some error occured.",
              });
            }
          },
        );
      } else {
        this.$store.dispatch("showLoader", false);
        this.selectedMonth = 0;
        this.selectedWeek = 0;
        this.selectedYear = 0;
        this.details = "";
        this.filterTriggered = false;
        this.showWeekError = false;
        this.showYearError = false;
        this.startDate = moment().subtract(7, "days").toDate();
        this.endDate = new Date();
      }
    },
    watch: {
      startDate(newValue) {
        if (newValue !== moment().subtract(7, "days").toDate()) {
          this.checkDate = true;
          this.selectedWeek = 0;
          this.selectedMonth = 0;
        }
      },
      endDate(newValue) {
        if (newValue !== new Date()) {
          this.checkDate = true;
          this.selectedWeek = 0;
          this.selectedMonth = 0;
        }
      },
      selectedWeek(newValue) {
        if (newValue !== 0) {
          this.selectedMonth = 0;
        }
      },
      selectedMonth(newValue) {
        if (newValue !== 0) {
          this.selectedWeek = 0;
        }
      },
    },
  },
};
</script>
<style scoped>
.question-id {
  display: none;
}
.table-head {
  border-radius: 0 !important;
}
table.qtbl {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}
.view-ques:hover {
  cursor: pointer;
}
.view-ques {
  text-decoration: underline;
  color: blue;
}
.custom-cell {
  white-space: normal;
  overflow-wrap: break-word;
  max-width: 300px;
}
.test-btn {
  font-weight: 600;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 9px 13px;
  cursor: pointer;
}
table.qtbl thead tr {
  background-color: #6a2f85;
  color: #fff;
}
#select-input {
  border-radius: 4px;
}
.date-picker-wrapper.disabled {
  pointer-events: none;
  opacity: 0.5;
  background-color: #f6f6f6;
  color: rgba(0, 0, 0, 0.42);
}
.input-box {
  padding-left: 0;
  border-radius: 4px;
}
</style>
